import { Route } from '@angular/router'
import { AuthGuard } from '@core/guards/auth.guard'
import { PageLayout } from '@modules/layout/page-layout.enum'
import { setLayout } from '@modules/layout/set-layout.resolver'

export type PlanRoutes = {
    index: Route
    subscribe: Route
    current: Route
}

export const planRoutes: PlanRoutes = {
    index: {
        path: 'plans',
        title: 'Available Plans',
        resolve: { layout: setLayout(PageLayout.Plan) },
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('./plan-list/plan-list.component').then((m) => m.PlanListComponent),
    },
    current: {
        path: 'plans/current',
        title: 'Current Plan',
        pathMatch: 'full',
        resolve: { layout: setLayout(PageLayout.Plan) },
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('./plan-current/plan-current.component').then((m) => m.PlanCurrentComponent),
    },
    subscribe: {
        path: 'plans/:id',
        title: 'Subscribe To Plan',
        resolve: { layout: setLayout(PageLayout.Plan) },
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('./plan-subscribe/plan-subscribe.component').then(
                (m) => m.PlanSubscribeComponent,
            ),
    },
}
